import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"
import Video from "../../videos/chmervideo-comp.mp4"
import { StaticImage } from "gatsby-plugin-image"

const BasicTextModule = () => {
  return (
    <BasicTextModuleStyles>
      <div className="container">
        <div className="textBox">
          {/* <div className="greetings__header__box"></div> */}
          <h1 className="hello">안녕하십니까?</h1>
          <h2 className="greetings">
            "고객 만족을 최우선으로 생각" 하는
            <br />
            (주)오유/오유테크코리아 입니다.
          </h2>
          <p className="greetings_description" style={{ marginBottom: "60px" }}>
            저희 회사는 CNC공작기계 및 CNC와이어컷 방전가공기를 전문 공급하는
            종합상사입니다. 다년간 고객의 성장과 더불어 발전하여 와이어-컷
            방전기 시장 점유율을 10% 이상대로 달성하였으며, 최고의 품질과 최선의
            서비스 정신으로 오로지 고객만족을 최우선의 목표로 매진하여 왔습니다.
            <br />
            <br />
            저희 오유테크 직원일동은 귀사와 더불어 최고의 품질을 추구하는
            제조산업 일선에 최고의 Solution을 제공하는 후원자가 되겠습니다.
            <br />
            <br />
            감사합니다.
          </p>
          {/* <div className="greetings__header__box"></div> */}
        </div>

        <StaticImage
          className="greetings__image"
          src="../../images/homeSlide/outechcompanypic-min.png"
          alt="company picture"
          layout="fullWidth"
          placeholder="blurred"
        />

        {/* <video
          className="greetings__video"
          src={Video}
          type="video/mp4"
          autoPlay
          loop
          muted
          playsInline
        /> */}
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
