import styled from "styled-components"

export const TimelineModuleStyle = styled.section`
  background: #fff;
  padding: 30px var(--borderSpacing);

  h2 {
    font-weight: 900;
    font-size: var(--bannerSubTitle);
  }

  .title {
    margin-top: 0;
    margin-bottom: 50px;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    color: #000;
    font-size: var(--bannerTitle);
    font-weight: 900;
    letter-spacing: 4px;
    @media (min-width: 1154px) {
      font-size: 4em;
    }
  }

  .text {
    font-size: var(--bannerSubTitle);
  }

  .date {
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 5px;
  }

  .vertical-timeline-element-content {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    box-sizing: border-box;
    border-top: 2px solid var(--primary);
    border-bottom-right-radius: 60%;
  }

  .vertical-timeline::before {
    border-left: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
    background: #fff;
  }
`
