import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import TimelineModule from "../components/Timeline/TimelineModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import { bannerData } from "../constants/homeData"
import Companies from "../components/Companies/Companies"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title={bannerData[0].title}
          subTitle={bannerData[0].subTitle}
        />
        <BasicTextModule />
        <Companies />
        {/* <TimelineModule /> */}
        <Features />
        {/* <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule> */}
      </Layout>
    </>
  )
}

export default Index
