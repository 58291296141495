import styled from "styled-components"

export const CompaniesStyles = styled.section`
  margin: 0;
  padding: 0;
  /* height: 100vh; */

  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 895px;
  }

  .box {
    position: relative;
    height: 895px;
    flex: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: 0.5s ease-in-out;
  }

  .box1:before,
  .box3:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #ceac146a;
    transition: 0.4s ease-in-out;
    opacity: 0;
    z-index: 0;
  }

  .box1:hover:before,
  .box3:hover:before {
    opacity: 0.8;
  }

  .bg__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .bg__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    background: #000;
    opacity: 0.35;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
    font-weight: 500;
  }

  .over {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
    font-weight: 500;
    z-index: 1;
    opacity: 0;
    transform: translateY(-70%);
    transition: 0.5s ease-in-out;
  }

  .box:hover .over {
    opacity: 1;
    transform: translateY(-100%);
  }

  .box1:hover .content,
  .box3:hover .content {
    opacity: 0;
    /* transition: 0.1s ease-in-out; */
  }

  .link {
    text-decoration: none !important;
    background: #fff;
    border-radius: 30px;
    font-weight: 900;
    cursor: pointer;
    color: #000;
    padding: 16px 28px;

    &:hover {
      color: #fff;
      background: #0f0f0f;
      transition: 0.5s;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 1244px) {
    .row {
      flex-direction: row;
    }
    .box {
      flex: 33%;
    }

    .icon {
      width: 80px;
      height: 80px;
    }
  }
`
