import * as React from "react"
import { Link, navigate } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { MdArrowDownward as Arrow } from "react-icons/md"
import Button from "../Button/Button"
import Video from "../../videos/millingvideo-comp.mp4"

const BannerModule = ({
  children,
  title,
  subTitle,
  series,
  enquire,
  manualLink,
}) => {
  function scrollToArea() {
    navigate("#topContent")
  }
  // React.useEffect(() => {
  //   console.log(Object.values(manualLink)[0])
  // }, [])

  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) : (
          <video
            className="banner__video"
            src={Video}
            type="video/mp4"
            autoPlay
            loop
            muted
            playsInline
          />
        )}
        <div className="video__cover"></div>

        <div className="container">
          <div className="banner__content">
            {subTitle && <h2>{subTitle}</h2>}
            {title && (
              <h1>
                {title}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h1>
            )}

            {series && (
              <h2 className="series">
                {series} 시리즈
                <span style={{ color: "var(--primary)" }}>.</span>
              </h2>
            )}
            {enquire && (
              <Button
                text="메뉴얼 다운로드"
                as={Link}
                to={
                  manualLink === null || manualLink === undefined
                    ? "/"
                    : Object.values(manualLink)[0]
                }
              />
            )}
            <button onClick={scrollToArea}>
              <Arrow />
            </button>
          </div>
        </div>
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
