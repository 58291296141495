import React from "react"
import { Link } from "gatsby"
import { CompaniesStyles } from "./CompaniesStyles"
import { StaticImage } from "gatsby-plugin-image"
import { FiExternalLink } from "react-icons/fi"

const Companies = () => {
  return (
    <CompaniesStyles>
      <div className="container">
        <div className="row">
          {/* Box1 */}
          <div className="box box1">
            <StaticImage
              src="../../images/chmer/chmerpic19-min.png"
              className="bg__img"
              layout="constrained"
              placeholder="blurred"
            />
            <div className="bg__cover"></div>
            <div className="content">
              <div className="icon__box">
                <StaticImage
                  src="../../images/icons/plasma-w.png"
                  layout="constrained"
                  placeholder="tracedSVG"
                  width={80}
                  height={80}
                  className="icon icon1"
                />
              </div>
              <div className="txt1">
                <p>Electrical Discharge Machining</p>
              </div>
              <div className="txt2">
                <h2>방전가공</h2>
              </div>
            </div>
            <div className="over">
              <div className="icon__box__over">
                <StaticImage
                  src="../../images/icons/plasma-w.png"
                  layout="constrained"
                  placeholder="tracedSVG"
                  width={80}
                  height={80}
                  className="icon icon1"
                />
              </div>
              <div className="txt1">
                <p>Electrical Discharge Machining</p>
              </div>
              <div className="txt2">
                <h2>방전가공</h2>
              </div>
              <Link to="/edm" className="link">
                자세히 보기 <FiExternalLink />
              </Link>
            </div>
          </div>
          {/* Box2 */}
          <div className="box box2">
            <StaticImage
              src="../../images/homeSlide/handshake.jpg"
              className="bg__img"
              layout="constrained"
              placeholder="blurred"
            />
            <div className="bg__cover"></div>
            <div className="content">
              <div className="icon">
                <StaticImage
                  src="../../images/icons/social-care-w.png"
                  layout="constrained"
                  placeholder="tracedSVG"
                  width={80}
                  height={80}
                  className="icon icon1"
                />
              </div>
              <div className="txt1">
                <p>CS Management</p>
              </div>
              <div className="txt2">
                <h2>차별화 된 고객만족경영</h2>
              </div>
            </div>
          </div>
          {/* Box3 */}
          <div className="box box3">
            <StaticImage
              src="../../images/homeSlide/milling.jpg"
              className="bg__img"
              layout="constrained"
              placeholder="blurred"
            />
            <div className="bg__cover"></div>
            <div className="content">
              <div className="icon">
                <StaticImage
                  src="../../images/icons/drilling-w.png"
                  layout="constrained"
                  placeholder="tracedSVG"
                  width={80}
                  height={80}
                  className="icon icon1"
                />
              </div>
              <div className="txt1">
                <p>Lathe Machines & Machining Center</p>
              </div>
              <div className="txt2">
                <h2>절삭가공</h2>
              </div>
            </div>
            <div className="over">
              <div className="icon__box__over">
                <StaticImage
                  src="../../images/icons/drilling-w.png"
                  layout="constrained"
                  placeholder="tracedSVG"
                  width={80}
                  height={80}
                  className="icon icon3"
                />
              </div>
              <div className="txt1">
                <p>Lathe Machines & Machining Center</p>
              </div>
              <div className="txt2">
                <h2>절삭가공</h2>
              </div>
              <Link to="/lathes" className="link">
                자세히 보기 <FiExternalLink />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CompaniesStyles>
  )
}

export default Companies
