export const bannerData = [
  {
    title: "OUTECH KOREA",
    subTitle: "최고의 품질을 제공하는",
  },
  {
    title: "EDM SERVICE",
    subTitle: "세계로 뻗어나가는",
  },
  {
    title: "CS MANAGEMENT",
    subTitle: "차별화 된 고객만족경영",
  },
  {
    title: "INNOVATIVE INDUSTRY",
    subTitle: "우수한 기술력을 갖춘",
  },
  {
    title: "CNC MACHINING COMPANY",
    subTitle: "독보적인 시장 점유율을 자랑하는",
  },
]
